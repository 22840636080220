module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-SZ5YH7T9KR"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","beta.playwithbot.com"],"delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Playwithbot","short_name":"PWB","description":"We are a online education platform trying to improve the effectiveness of the education process with practical solutions, fun learning methods and technology.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","orientation":"portrait","crossOrigin":"use-credentials","icon":"src/static/favicons/fav_logo.svg","icons":[{"src":"static/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"68bbf804b501e2903d99c9191ab1d7c2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
